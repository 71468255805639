<template>
  <div>
    <h1>404 - Page Not Found</h1>
    <p>The page you are looking for does not exist.</p>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
};
</script>

<style scoped>
h1 {
  color: #ffce2a;
}
</style>