<template>
  <div class="container bg ScaleBox" ref="ScaleBox" 
    :style="{
      width: width + 'px',
      height: height + 'px',
    }">
    <div class="header" style="padding-top: 20px;">
      <div class="header-left" style="padding-left: 180px; display: flex;">
        <img class="logo" src="./assets/logo.png" style="width: 331px;height: 60px;padding-left: 60px;">
      </div>
      <div class="header-title" style="display:flex;justify-self: space-between;margin:10px;">
        <div style="flex:0 1 25%">
        <dv-decoration-10 style="height:5px;"/>
        </div>

        <div style="flex:0 1 50%">
          <div class="d-flex">
            <dv-decoration-8 style="height:50px;flex:1;" />
            <h2 style="width: 260px;">大规模泛金融知识图谱系统</h2>
            <!-- <div style="font-size: large;">大规模泛金融知识图谱系统</div> -->
            <dv-decoration-8 :reverse="true" style="height:50px;flex:1;" />
          </div>
          
        </div>

        <div style="flex:0 1 25%">
          <dv-decoration-10 style="height:5px;transform:rotateY(180deg)" />
        </div>
      </div>
      <div class="header-right">

      </div>
    </div>
    <div class="main">
      <!-- 第一块 -->
      <div class="box">
        <div class="box-header" style="">
          <span style=""></span>
          板块1
        </div>
        <div class=" box-body">
          <!-- 第一行 -->
          <div class="charts-row" style="">
            <div style="flex:0 1 33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">类型统计</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-decoration-9 style="width:120px;height:120px;margin-right:30px;" dur=5><h1 style="color: rgba(255,215,0);">48</h1></dv-decoration-9>
                  <dv-decoration-9 style="width:120px;height:120px;margin-left: 30px;" dur=5><h1 style="color: rgba(255,215,0);">38</h1></dv-decoration-9>
              </div> 
              <div style="display: flex;justify-content: center;align-items: center;">
                <span style="padding-right: 50px;">节点类型总数</span>
                <span style="padding-left: 50px;">边类型总数</span>
              </div>  

              </dv-border-box-13>
            </div>
            <div style="flex:0 1 34%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">类型统计</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-decoration-9 style="width:120px;height:120px;margin-right:30px;" dur=5><h1 style="color: rgba(255,215,0);">48</h1></dv-decoration-9>
                  <dv-decoration-9 style="width:120px;height:120px;margin-left: 30px;" dur=5><h1 style="color: rgba(255,215,0);">38</h1></dv-decoration-9>
              </div> 
              <div style="display: flex;justify-content: center;align-items: center;">
                <span style="padding-right: 50px;">节点类型总数</span>
                <span style="padding-left: 50px;">边类型总数</span>
              </div>  
              </dv-border-box-13>
            </div>
            <div style="flex:0 1 33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">类型统计</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-decoration-9 style="width:120px;height:120px;margin-right:30px;" dur=5><h1 style="color: rgba(255,215,0);">48</h1></dv-decoration-9>
                  <dv-decoration-9 style="width:120px;height:120px;margin-left: 30px;" dur=5><h1 style="color: rgba(255,215,0);">38</h1></dv-decoration-9>
              </div> 
              <div style="display: flex;justify-content: center;align-items: center;">
                <span style="padding-right: 50px;">节点类型总数</span>
                <span style="padding-left: 50px;">边类型总数</span>
              </div>  
              </dv-border-box-13>
            </div>
          </div>

          <!-- 第二行 -->
          <div class="charts-row" style="">
            <div style="flex:0 1 33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">健康指数</h3>
                <RadarChart/>
              </dv-border-box-13>
            </div>
            <div style="flex:0 1 34%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">健康指数</h3>
                <RadarChart/>
                <!-- <dv-capsule-chart :config="config" style="width:100%;height:350px" /> -->
              </dv-border-box-13>
            </div>
            <div style="flex:0 1 33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">健康指数</h3>
                <RadarChart/>
              </dv-border-box-13>
            </div>
          </div>

          <!-- 第三行 -->
          <div class="charts-row" style="">
            <div style="flex:0 1 33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">异常监测结果</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-scroll-board :config="config22" style="width:95%;height:220px;" />
                </div>
              </dv-border-box-13>
            </div>
            <div style="flex:0 1 34%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">异常监测结果</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-scroll-board :config="config22" style="width:95%;height:220px;" />
                </div>
              </dv-border-box-13>
            </div>
            <div style="flex:0 1 33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">异常监测结果</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-scroll-board :config="config22" style="width:95%;height:220px;" />
                </div>
              </dv-border-box-13>
            </div>
          </div>
        </div>
        <!-- <div class="box-footer">
          <span style=""></span>
        </div> -->
      </div>
      <!-- 第二块 -->
      <div class="box">
        <div class="box-header" style="">
          <span style=""></span>
          板块2
        </div>
        <div class="box-body">
          <!-- 第一行 一列 课程概览 -->
          <div class="charts-row">
            <div style="flex:0 1 25%">
              <dv-border-box-13 style="width:100%;height:200px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">类型统计</h3>
                <!-- <Chart /> -->
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-decoration-9 style="width:120px;height:120px;margin-right:30px;" dur=5><h1 style="color: rgba(255,215,0);">48</h1></dv-decoration-9>
                  <dv-decoration-9 style="width:120px;height:120px;margin-left: 30px;" dur=5><h1 style="color: rgba(255,215,0);">38</h1></dv-decoration-9>
              </div> 
              <div style="display: flex;justify-content: center;align-items: center;">
                <span style="padding-right: 50px;">节点类型总数</span>
                <span style="padding-left: 50px;">边类型总数</span>
              </div>  

              
              </dv-border-box-13>

              <dv-border-box-13 style="width:100%;height:400px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">健康指数</h3>
                <RadarChart/>
                <!-- <dv-capsule-chart :config="config" style="width:100%;height:350px" /> -->
              </dv-border-box-13>
            </div>

            <div style="flex:0 1 50%">
              <dv-border-box-1 style="width:100%;height:600px;">
              <!-- 图谱 -->
              <iframe src="/static/3d-force-graph/index.html" ref="iframe" width="100%" height="600px" scrolling="no"></iframe>
              </dv-border-box-1>
            </div>

            <div style="flex:0 1 25%">
              <dv-border-box-13 style="width:100%;height:200px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">图谱统计</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-decoration-9 style="width:123px;height:123px;margin-right:30px;" dur=5><h2 style="color: rgba(255,215,0);">606041</h2></dv-decoration-9>
                  <dv-decoration-9 style="width:123px;height:123px;margin-left: 30px;" dur=5><h2 style="color: rgba(255,215,0);">1694475</h2></dv-decoration-9>
              </div> 
              <div style="display: flex;justify-content: center;align-items: center;">
                <span style="padding-right: 60px;">节点总数</span>
                <span style="padding-left: 55px;">边总数</span>
              </div>  
              </dv-border-box-13>

              <dv-border-box-13 style="width:100%;height:400px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">区域分析</h3>
                <Map />
              </dv-border-box-13>
            </div>
          </div>

          <!-- 第二行 -->
          <div class="charts-row" style="">
            <div style="flex:0 1 25%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">舆情预警</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-scroll-board :config="config11" style="width:95%;height:220px;justify-content: center;align-items: center" />
                </div>
              </dv-border-box-13>
            </div>

            <div style="flex:0 1 50%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">系统性风险指数</h3>
                <LineChart />
              </dv-border-box-13>
            </div>

            <div style="flex:0 1 25%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">异常监测结果</h3>
                <!-- <CapsuleChart :dataChart="items"/> -->
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-scroll-board :config="config22" style="width:95%;height:220px;" />
                </div>
                
              </dv-border-box-13>
            </div>
          </div>
        </div>
        <!-- <div class="box-footer">
          <span style=""></span>
        </div> -->
      </div>
      <!-- 第三块 -->
      <div class="box">
        <div class="box-header" style="">
          <span style=""></span>
          板块1
        </div>
        <div class=" box-body">
          <!-- 第一行 -->
          <div class="charts-row" style="">
            <div style="flex:0 1 33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">类型统计</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-decoration-9 style="width:120px;height:120px;margin-right:30px;" dur=5><h1 style="color: rgba(255,215,0);">48</h1></dv-decoration-9>
                  <dv-decoration-9 style="width:120px;height:120px;margin-left: 30px;" dur=5><h1 style="color: rgba(255,215,0);">38</h1></dv-decoration-9>
              </div> 
              <div style="display: flex;justify-content: center;align-items: center;">
                <span style="padding-right: 50px;">节点类型总数</span>
                <span style="padding-left: 50px;">边类型总数</span>
              </div>  

              
              </dv-border-box-13>
            </div>
            <div style="flex:0 1 34%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">类型统计</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-decoration-9 style="width:120px;height:120px;margin-right:30px;" dur=5><h1 style="color: rgba(255,215,0);">48</h1></dv-decoration-9>
                  <dv-decoration-9 style="width:120px;height:120px;margin-left: 30px;" dur=5><h1 style="color: rgba(255,215,0);">38</h1></dv-decoration-9>
              </div> 
              <div style="display: flex;justify-content: center;align-items: center;">
                <span style="padding-right: 50px;">节点类型总数</span>
                <span style="padding-left: 50px;">边类型总数</span>
              </div>  

              
              </dv-border-box-13>
            </div>
            <div style="flex:0 1 33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">类型统计</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-decoration-9 style="width:120px;height:120px;margin-right:30px;" dur=5><h1 style="color: rgba(255,215,0);">48</h1></dv-decoration-9>
                  <dv-decoration-9 style="width:120px;height:120px;margin-left: 30px;" dur=5><h1 style="color: rgba(255,215,0);">38</h1></dv-decoration-9>
              </div> 
              <div style="display: flex;justify-content: center;align-items: center;">
                <span style="padding-right: 50px;">节点类型总数</span>
                <span style="padding-left: 50px;">边类型总数</span>
              </div>  
              </dv-border-box-13>
            </div>
          </div>

          <!-- 第二行 -->
          <div class="charts-row" style="">
            <div style="flex:0 1 33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">健康指数</h3>
                <RadarChart/>
                <!-- <dv-capsule-chart :config="config" style="width:100%;height:350px" /> -->
              </dv-border-box-13>
            </div>
            <div style="flex:0 1 34%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">健康指数</h3>
                <RadarChart/>
                <!-- <dv-capsule-chart :config="config" style="width:100%;height:350px" /> -->
              </dv-border-box-13>
            </div>
            <div style="flex:0 1 33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">健康指数</h3>
                <RadarChart/>
                <!-- <dv-capsule-chart :config="config" style="width:100%;height:350px" /> -->
              </dv-border-box-13>
            </div>
          </div>

          <!-- 第三行 -->
          <div class="charts-row" style="">
            <div style="flex:0 1 33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">异常监测结果</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-scroll-board :config="config22" style="width:95%;height:220px;" />
                </div>
              </dv-border-box-13>
            </div>
            <div style="flex:0 1 34%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">异常监测结果</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-scroll-board :config="config22" style="width:95%;height:220px;" />
                </div>
              </dv-border-box-13>
            </div>
            <div style="flex:0 1 33%">
              <dv-border-box-13 style="width:100%;height:300px;">
                <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">异常监测结果</h3>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <dv-scroll-board :config="config22" style="width:95%;height:220px;" />
                </div>
              </dv-border-box-13>
            </div>
          </div>
        </div>
        <!-- <div class="box-footer">
          <span style=""></span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Map from './components/Map.vue'
import RadarChart from './components/RadarChart.vue'
import CapsuleChart from './components/CapsuleChart.vue';
import LineChart from './components/LineChart.vue'
export default {
  name: 'App',
  components: {
    Map,
    RadarChart,
    CapsuleChart,
    LineChart
  },
  data() {
    return {
      scale: 0,
      width: 5760,
      height: 1080,
      config:{
        data: [
          {
            name: '南阳',
            value: 167
          },
          { 
            name: '周口',
            value: 67
          },
          {
            name: '漯河',
            value: 123
          },
          {
            name: '郑州',
            value: 55
          },
          {
            name: '西峡',
            value: 98
          }
        ],
        colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
        unit: '单位',
        showValue: true
      },
      config2:{
        data: [66, 45],
        shape: 'roundRect'
      },
      config3:{
        data: [
          {
            name: '周口',
            value: 55
          },
          {
            name: '南阳',
            value: 120
          },
          {
            name: '西峡',
            value: 71
          },
          {
            name: '驻马店',
            value: 66
          },
          {
            name: '新乡',
            value: 80
          },
          {
            name: '信阳',
            value: 35
          },
          {
            name: '漯河',
            value: 15
          }
        ],
        img: [
          require('./assets/1st.png'),
          require('./assets/2st.png'),
          require('./assets/3st.png'),
          require('./assets/4st.png'),
          require('./assets/5st.png'),
          require('./assets/6st.png'),
          require('./assets/7st.png'),
        ]
      },
      config4:{
        centerPoint: [0.48, 0.35],
        points: [
          {
            position: [0.52, 0.235],
            text: '新乡'
          },
          {
            position: [0.43, 0.29],
            text: '焦作'
          },
          {
            position: [0.59, 0.35],
            text: '开封'
          },
          {
            position: [0.53, 0.47],
            text: '许昌'
          },
          {
            position: [0.45, 0.54],
            text: '平顶山'
          },
          {
            position: [0.36, 0.38],
            text: '洛阳'
          },
          {
            position: [0.62, 0.55],
            text: '周口'
          },
          {
            position: [0.56, 0.56],
            text: '漯河'
          },
          {
            position: [0.37, 0.66],
            text: '南阳'
          },
          {
            position: [0.55, 0.81],
            text: '信阳'
          },
          {
            position: [0.55, 0.67],
            text: '驻马店'
          },
          {
            position: [0.37, 0.29],
            text: '济源'
          },
          {
            position: [0.20, 0.36],
            text: '三门峡'
          },
          {
            position: [0.76, 0.41],
            text: '商丘'
          },
          {
            position: [0.59, 0.18],
            text: '鹤壁'
          },
          {
            position: [0.68, 0.17],
            text: '濮阳'
          },
          {
            position: [0.59, 0.10],
            text: '安阳'
          }
        ],
        bgImgUrl: require('./assets/map.jpg'),
        centerPointImg: {
          url: require('./assets/mapCenterPoint.png')
        },
        pointsImg: {
          url: require('./assets/mapPoint.png')
        }
      },

      // 表格设置
      config11 : { 
        header: ['主体代码&nbsp', '行业', '&nbsp&nbsp&nbsp预警时刻','&nbsp&nbsp风险水平','风险类型&nbsp'],
        data: [
            {
              主体代码:'688001.SH',
              行业:'计算机',
              预警时刻:'2023/6/29 16:00',
              风险水平:'严重',
              风险类型:'流动风险',
            },
            {
              主体代码:'688002.SH',
              行业:'计算机',
              当前预警时刻:'2023/6/29 16:00',
              预警类型:'严重',
              风险类型:'市场风险',
            },
            {
              主体代码:'688002.SH',
              行业:'计算机',
              当前预警时刻:'2023/6/29 16:00',
              预警类型:'严重',
              风险类型:'市场风险',
            },
            {
              主体代码:'688002.SH',
              行业:'计算机',
              当前预警时刻:'2023/6/29 16:00',
              预警类型:'严重',
              风险类型:'市场风险',
            },
            {
              主体代码:'688002.SH',
              行业:'计算机',
              当前预警时刻:'2023/6/29 16:00',
              预警类型:'严重',
              风险类型:'市场风险',
            },
          ],
        

      evenRowBGC:'#0A2732',
      oddRowBGC:'0A2732',
      headerBGC:'rgba(61,89,171,0.5)',
      align: ['right'],
      

      },


      config22 :
      { 
        data: [
        ['教育',  7],
        ['金融业',  1],
        ['房地产业',  2],
        ['制造业',  3],
        ['房地产业',  4],
        ['批发和零售业',  5],

      ], 
      evenRowBGC:'#0A2732',
      oddRowBGC:'0A2732',
      

      },

      items:[
          {
          name: '股权质押',
          value: 683
          },
          {
          name: '转让股价',
          value: 234
          },
          {
          name: '股份增持',
          value: 240
          },
          {
          name: '债券发行',
          value: 523
          },
          {
          name: '高管变动',
          value: 345
          },
          {
          name: '产品发布',
          value: 320
          },
          {
          name: '破产',
          value: 280
          },
          {
          name: '业绩下滑',
          value: 271
          },
      ]
    }
  },
  methods: {
    getScale() {
        // 固定好16：9的宽高比，计算出最合适的缩放比
        const { width, height } = this;
        const wh = window.innerHeight / height;
        const ww = window.innerWidth / width;
        console.log(ww < wh ? ww : wh);
        return ww < wh ? ww : wh;
      },
    setScale() {
      // 获取到缩放比例，设置它
      this.scale = this.getScale();
      if (this.$refs.ScaleBox) {
        this.$refs.ScaleBox.style.setProperty("--scale", this.scale);
      }
    },
    debounce(fn, delay) {
      const delays = delay || 500;
      let timer;
      return function () {
        const th = this;
        const args = arguments;
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(function () {
          timer = null;
          fn.apply(th, args);
        }, delays);
      };
    },
  },
  mounted() {
      this.setScale();
      window.removeEventListener("resize",this.debounce(this.setScale))
      window.addEventListener("resize", this.debounce(this.setScale));
  }
}
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

body,
input,
button,
a,
textarea,
select {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, "Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;

}

div {
  box-sizing: border-box;
}

ul li {
  list-style: none;
}
.ScaleBox {
    position: absolute;
    transform: scale(var(--scale)) translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    transform-origin: 0 0;
    left: 50%;
    top: 50%;
    transition: 0.3s;
    z-index: 999;
    // background: rgba(255, 0, 0, 0.3);
  }
.bg {
    padding:0;
    background:url('./assets/pageBg.png') center center;
    /* background-size:cover; */
  }
.container {
  color: aliceblue;
  // width: 5760px;
  // height: 1080px;
  // background-color: #18478F;

  >.header {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 100px; //

    
    .header-left,
    .header-right {
      flex: 0 1 20%;

      .el-button {
        border: none;
        transform: skewX(45deg);
        width: 180px;
        height: 50px;
        margin-left: 30px;
      }

      .el-button--primary {
        background-color: rgba(0, 102, 172, 0.4);
        border-color: #0066AC;
      }

      .el-button--primary:focus {
        background-color: #BDA158;
      }
    }

    .header-title {
      flex: 0 1 32%
    }
  }
  .main {
  width: 100%;
  height: 980px; //
  display: flex;
  justify-content: space-evenly;

  .box {
    flex: 0 1 32%;
    height: 940px; //
    // background-color: #0C2B61;
    
    .box-header {
      // background-color: #18478F;
      text-align: center;
      height: 40px; //
      line-height: 40px;
      border: 1px solid rgb(40, 110, 216);

      span {
        float: left;
        width: 0;
        height: 0;
        // border-top: 30px solid #1596ca;
        // border-right: 30px solid transparent;
      }
    }

    .box-body {
      height: 850px; //

      .charts-row {
        display: flex;

        .chart-container {
          width: 50%;
          padding-right: 40px;
        }

        .chart-title {
          text-align: left;
          height: 10%; //
          padding-top: 8px;

          .icon {
            width: 15px;
            height: 15px;
            padding-left: 8px;
          }
        }

        .chart {
          height: 90%; //
        }
      }
    }

    .box-footer {
      height: 30px;
      position: relative;

      span {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-bottom: 25px solid #1596ca;
        border-left: 25px solid transparent;
      }
    }
  }
  }

}
</style>
