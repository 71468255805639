<template>
  <div>
    <div class="module-box">
    <div style="flex:0 1 25%">
      <!-- 股票市场 -->
      <dv-border-box-13 style="width:100%;height:200px;">
        <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">股票市场</h3>
        <div style="display: flex;justify-content: space-evenly;align-items: center;">
          <dv-decoration-9 style="width:120px;height:120px;" dur=5><h1 style="color: rgba(255,215,0);">1100</h1></dv-decoration-9>
          <dv-decoration-9 style="width:120px;height:120px;" dur=5><h1 style="color: rgba(255,215,0);">1184</h1></dv-decoration-9>
          <dv-decoration-9 style="width:120px;height:120px;" dur=5><h1 style="color: rgba(255,215,0);">1254</h1></dv-decoration-9>
      </div> 
       <div style="display: flex;justify-content: space-evenly;align-items: center;">
        <span >低风险</span>
        <span >中风险</span>
        <span >高风险</span>
      </div>  
      </dv-border-box-13>
      <!-- 雷达图 -->
      <dv-border-box-13 style="width:100%;height:400px;">
        <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">健康指数</h3>
        <div ref="echarts_3" style="height:80%;width:100%"></div>
      </dv-border-box-13>
    </div>
    <!-- 3D -->
    <div style="flex:0 1 50%">
      <dv-border-box-1 style="width:100%;height:600px;">
       <!-- 图谱 -->
       <iframe src="/static/3d-force-graph/index1.html" ref="iframe" width="100%" height="600px" scrolling="no"></iframe>
      </dv-border-box-1>
    </div>

    <div style="flex:0 1 25%">
      <!-- 债券市场 -->
      <dv-border-box-13 style="width:100%;height:200px;">
        <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">债券市场</h3>
        <div style="display: flex;justify-content: space-evenly;align-items: center;">
          <dv-decoration-9 style="width:120px;height:120px;" dur=5><h1 style="color: rgba(255,215,0);">1862</h1></dv-decoration-9>
          <dv-decoration-9 style="width:120px;height:120px;" dur=5><h1 style="color: rgba(255,215,0);">441</h1></dv-decoration-9>
          <dv-decoration-9 style="width:120px;height:120px;" dur=5><h1 style="color: rgba(255,215,0);">87</h1></dv-decoration-9>
      </div> 
       <div style="display: flex;justify-content: space-evenly;align-items: center;">
        <span >低风险</span>
        <span >中风险</span>
        <span >高风险</span>
      </div>  
      </dv-border-box-13>

      <dv-border-box-13 style="width:100%;height:400px;">
        <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">区域分析</h3>
        <Map />
      </dv-border-box-13>
    </div>
  </div>  
  
  <!-- 一行二等分 -->
  <div class="module-box">
    <div style="flex:0 1 25%">
       <dv-border-box-13 style="width:100%;height:300px;">
        <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">异常监测结果</h3>
        <div style="display: flex;justify-content: center;align-items: center;">
          <dv-scroll-board :config="config22" style="width:95%;height:220px;" />
        </div>
      </dv-border-box-13>
    </div>
   

    <div style="flex:0 1 50%">
      <dv-border-box-13 style="width:100%;height:300px;">
        <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px"></h3>
        <!-- 维度时序 -->
        <dimension-component :zoom="datazoom" ref="dimension"/>

      </dv-border-box-13>
    </div>

    <div style="flex:0 1 25%">
      <dv-border-box-13 style="width:100%;height:300px;">
        <h3 style="padding-top: 15px;padding-left: 15px;padding-right: 15px">宏观经济指标</h3>
        <div ref="chartContainer" style="width:100%; height: 90%;margin: 1px"></div>
        
      </dv-border-box-13>
    </div>
    
  </div>

  </div>
  
</template>

<script>
import Map from './Map.vue'
import RadarChart from './RadarChart.vue'
import CapsuleChart from './CapsuleChart.vue';
import LineChart from './LineChart.vue'
import dimensionComponent from "./components/dimensionComponent";

import http from "@/utils/request";
import * as echarts from "echarts";

export default {
  components:{
    Map,
    RadarChart,
    CapsuleChart,
    LineChart,
    dimensionComponent
  },
  data(){
    return {
      config:{
        data: [
          {
            name: '南阳',
            value: 167
          },
          { 
            name: '周口',
            value: 67
          },
          {
            name: '漯河',
            value: 123
          },
          {
            name: '郑州',
            value: 55
          },
          {
            name: '西峡',
            value: 98
          }
        ],
        colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
        unit: '单位',
        showValue: true
      },
      config2:{
        data: [66, 45],
        shape: 'roundRect'
      },
      config3:{
        data: [
          {
            name: '周口',
            value: 55
          },
          {
            name: '南阳',
            value: 120
          },
          {
            name: '西峡',
            value: 71
          },
          {
            name: '驻马店',
            value: 66
          },
          {
            name: '新乡',
            value: 80
          },
          {
            name: '信阳',
            value: 35
          },
          {
            name: '漯河',
            value: 15
          }
        ],
        img: [
          require('../assets/img/1st.png'),
          require('../assets/img/2st.png'),
          require('../assets/img/3st.png'),
          require('../assets/img/4st.png'),
          require('../assets/img/5st.png'),
          require('../assets/img/6st.png'),
          require('../assets/img/7st.png'),
        ]
      },
      config4:{
        centerPoint: [0.48, 0.35],
        points: [
          {
            position: [0.52, 0.235],
            text: '新乡'
          },
          {
            position: [0.43, 0.29],
            text: '焦作'
          },
          {
            position: [0.59, 0.35],
            text: '开封'
          },
          {
            position: [0.53, 0.47],
            text: '许昌'
          },
          {
            position: [0.45, 0.54],
            text: '平顶山'
          },
          {
            position: [0.36, 0.38],
            text: '洛阳'
          },
          {
            position: [0.62, 0.55],
            text: '周口'
          },
          {
            position: [0.56, 0.56],
            text: '漯河'
          },
          {
            position: [0.37, 0.66],
            text: '南阳'
          },
          {
            position: [0.55, 0.81],
            text: '信阳'
          },
          {
            position: [0.55, 0.67],
            text: '驻马店'
          },
          {
            position: [0.37, 0.29],
            text: '济源'
          },
          {
            position: [0.20, 0.36],
            text: '三门峡'
          },
          {
            position: [0.76, 0.41],
            text: '商丘'
          },
          {
            position: [0.59, 0.18],
            text: '鹤壁'
          },
          {
            position: [0.68, 0.17],
            text: '濮阳'
          },
          {
            position: [0.59, 0.10],
            text: '安阳'
          }
        ],
        bgImgUrl: require('../assets/img/map.jpg'),
        centerPointImg: {
          url: require('../assets/img/mapCenterPoint.png')
        },
        pointsImg: {
          url: require('../assets/img/mapPoint.png')
        }
      },

      // 表格设置
      config1 : { 
          header: ['主体代码&nbsp', '行业', '&nbsp&nbsp&nbsp&nbsp&nbsp预警时刻','&nbsp&nbsp&nbsp风险水平','风险类型&nbsp'],
          data: [
              {
                主体代码:'688001.SH',
                行业:'计算机',
                预警时刻:'2023/6/29 16:00',
                风险水平:'严重',
                风险类型:'流动风险',
              },
              {
                主体代码:'688002.SH',
                行业:'计算机',
                当前预警时刻:'2023/6/29 16:00',
                预警类型:'严重',
                风险类型:'市场风险',
              },
              {
                主体代码:'688002.SH',
                行业:'计算机',
                当前预警时刻:'2023/6/29 16:00',
                预警类型:'严重',
                风险类型:'市场风险',
              },
              {
                主体代码:'688002.SH',
                行业:'计算机',
                当前预警时刻:'2023/6/29 16:00',
                预警类型:'严重',
                风险类型:'市场风险',
              },
              {
                主体代码:'688002.SH',
                行业:'计算机',
                当前预警时刻:'2023/6/29 16:00',
                预警类型:'严重',
                风险类型:'市场风险',
              },
            ],
          

        evenRowBGC:'#0A2732',
        oddRowBGC:'0A2732',
        headerBGC:'rgba(61,89,171,0.5)',
        align: ['right'],
        

      },
      config22 :
        { 
          data: [
          ['教育',  7],
          ['金融业',  1],
          ['房地产业',  2],
          ['制造业',  3],
          ['房地产业',  4],
          ['批发和零售业',  5],

        ], 
        evenRowBGC:'#0A2732',
        oddRowBGC:'0A2732',
        

      },
      items:[
          {
          name: '股权质押',
          value: 683
          },
          {
          name: '转让股价',
          value: 234
          },
          {
          name: '股份增持',
          value: 240
          },
          {
          name: '债券发行',
          value: 523
          },
          {
          name: '高管变动',
          value: 345
          },
          {
          name: '产品发布',
          value: 320
          },
          {
          name: '破产',
          value: 280
          },
          {
          name: '业绩下滑',
          value: 271
          },
      ],
      //
      date:[],
      rate:[],
      macro:[],
      montary:[],
      income:[],
      investment:[],
      loan:[],
      lever:[],
      other:[],
      whole:[],
      title:['利率','货币','信贷','宏观指标','收入和融资','银行理财','整体杠杆','其他']
    }
  },
  mounted(){
    // 自动登录
    const params = new URLSearchParams();
    params.append('username', 'gongdragon');
    params.append('password', 'gwl123');
    http.post('/users/login/', params)
    .then((res)=>{
      if (res.data.message===1){
        this.loading = false
        let user={token:res.data.data,name:'gongdragon',stats:res.data.stats}
        this.$store.commit('login',user)
      } else {
        this.$message({
          showClose: true,
          message: res.data.data,
          type: 'warning'
        });
        this.loading = false
      }
    }).then(()=>{

      // 健康指数 雷达图
    http.get(
            "/health/health_search/",
            {params:{
                name: '上海纳尔实业股份有限公司',//公司名
                date: '2024-01',//时间（年份+季度）
    }}).then(response => {
      console.log('Response',response);
      
      // let radarDatas// 存放所有数据项
      // radarDatas = response.data.data.map(item => {
      //   // 单个数据项 转化为echarts格式
      //   let radarData = [item.投资关系,item.操盘模式,item.财务状态,item.运行状态,item.规模扩张,item.声誉风险,item.外部环境]
      //   return radarData
      // })
      // console.log('radarDatas',radarDatas)
      let radarData = []
      for (let i in response.data.data) {
        if (i !== '健康指数' && i !== '等级' && typeof response.data.data[i] === 'number') radarData.push(response.data.data[i])
      }
    console.log('radarData',radarData);
    
      const echarts3 = echarts.init(this.$refs.echarts_3)
      const option = {
        tooltip: {},
        legend: {
            data: ['纳尔实业', '平安保险','招商银行'],
            x:"center",
            y:'bottom',
            textStyle:{
                color:"#fff"
            }
        },
        color: ['red', 'yellow', 'blue'],
        radar: {
            name:{
                textStyle: {
                    //设置颜色
                    color:'#fff'
                }
            },
            indicator: [
            { name: '投资关系', max: 100, color:'#fff'},
            { name: '操盘模式', max: 100, color:'#fff' },
            { name: '财务状态', max: 100, color:'#fff' },
            { name: '运行状态', max: 100, color:'#fff' },
            { name: '规模扩张', max: 100, color:'#fff' },
            { name: '声誉风险', max: 100, color:'#fff' },
            { name: '外部环境', max: 100, color:'#fff' }
            ],
            center: ['50%','50%'],
            radius: "58%"
        },
        series: [{
            name: '',
            type: 'radar',
            itemStyle : {
                normal : {
                    splitLine: {
                        lineStyle: {

                        }
                    },
                    label: {
                        show: false,
                        textStyle:{
                        },
                        formatter:function(params) {
                            return params.value;}
                    },
                }
            },
            data : [
                {
                    value : [82.44588614888224,25,54.00000000000001,58.56999999999999,20,65,5],
                    name : '纳尔实业'
                },
                {
                    value : [50.033,45,58.8,62.73,10,65,50],
                    name : '平安保险'
                },
                {
                    value : [43.064,50,53.6,64.23,10,75,50],
                    name : '招商银行'
                }
            ]
        }]
    };
      echarts3.setOption(option)
    })
    // 轮播图
    http.get('/anomaly/item_anomaly/',{params:{
      sc:'stock',
    }}).then((res)=>{
      // console.log(res.data.data);
      const formatData = res.data.data.map(item => {
        return [item.监测时间,item.名称,item.异常关联类型]
      })
      const config22 = {
        header: ['监测时间', '名称', '异常关联类型'],
        data: formatData,
        // headerBGC:'black',
        evenRowBGC:'#0A2732',
        oddRowBGC:'0A2732',
      }
      // console.log('formatData',formatData);
      this.config22 = config22
    })

    //系统性
    this.$refs.dimension.getData()

    // 宏观分析折线图
    http.get('/risk/future_risk/').then((res)=>{
        this.date=res.data.date
        this.rate=res.data.rate
        this.macro=res.data.macro
        this.montary=res.data.montary
        this.income=res.data.income
        this.investment=res.data.investment
        this.loan=res.data.loan
        this.lever=res.data.lever
        this.other=res.data.other
        this.whole.push(this.rate)
        this.whole.push(this.montary)
        this.whole.push(this.loan)
        this.whole.push(this.macro)
        this.whole.push(this.income)
        this.whole.push(this.investment)
        this.whole.push(this.lever)
        this.whole.push(this.other)
      }).then(()=>{
        for(let i = 0; i < 6; i++) {
        // let chartContainer = 'chartContainer' + i
        let echart = echarts.init(this.$refs.chartContainer)

        const option = {
          title:{
            text:this.title[4],
            textStyle:{
            color:'#fff'
            },
            left:'center'
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            top:30,
            textStyle:{
            color:'#fff'
            },
          },
          xAxis: {
            type: 'category',
            data: this.date,
            axisLabel: {//x轴文字的配置
                show: true,
                textStyle: {
                    color: "#fff",
                }
            },
          },
          yAxis: {
            type: 'value',
            axisLabel: {//x轴文字的配置
                show: true,
                textStyle: {
                    color: "#fff",
                }
            },
          },
          grid:{
            containLabel:true
          },
          series: this.whole[4]
        };
        echart.setOption(option)
      }}
      )


    })
  }

}
</script>
